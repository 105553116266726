<template>
    <div style="width: 100%;background: #E3E4E7;display: flex;justify-content: center;min-height: 100%;overflow: hidden;">

        <!--  中间部分 -->
        <div style="width:1300px;overflow-x: hidden;overflow-y: hidden;">
            <div class="avatar-uploader" style="overflow: hidden">
                <div style="width: 1280px;" >
                    <div style="margin: 0px 5px;background: #FFFFFF;overflow:auto;height: 845px;">
                        <div style="width:1257px;float: left;">
                            <div style="height: 40px;margin:10px 13px;border: none;">
                                <el-input style="width:1240px;height: 40px;border: none;font-size: 26px;font-weight: bold;" v-model="title" placeholder="请输入文章标题（不超过50个字）" maxlength="50"></el-input>
                            </div>
                            <!--编辑器-->
                            <div style="margin-left: 13px;">
                                <div style="width:1240px;min-height:700px;">
                                    <Editor  style="border: 1px solid #999999;" :tinymce_height="'700'" :innerDrawer = "innerDrawer = true" :value="content"  ref="ch" ></Editor>
                                </div>
                            </div>
                            <!-- 底部按钮 -->
                            <template>
                                <label >
                                    <div @click="add" style="width: 130px;height: 40px;margin: 20px 5px;float: right">
                                        <el-button type="primary" class="el-icon-position">保存</el-button>
                                    </div>
                                </label>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--  右侧部分 -->
        <div style="background: #FFFFFF;height: 868px;width: 400px;margin-left: 5px;">
            <el-button style="float:left;background: #2970FF;height: 34px;margin: 20px;" type="info" size="mini" @click="create_thumb_pic()" slot="reference">获取内容图片</el-button>
            <el-dialog
                    title="图片封面"
                    :visible.sync="dialogShow"
                    :style="{minHeight: '100px'}"
                    center>
                <div style="width: 100%;">
                    <div style="width: 25%;height: 150px;float: left;" v-for="(item,index) in cover_imgs" :key="index" @click="change_border(index)" :class="{'active':index==coverIndex}">
                        <img style="width: 100%;height:100%;overflow: hidden;" :src="item+'-300x150'">
                    </div>
                </div>
            </el-dialog>
            <div style="height: 200px;">
                <div style="width: 156px;height: 106px;margin-left: 7px;">
                    <el-upload style="margin-left: 12px;width: 156px;height: 106px;"  drag action="" :http-request="upload_img2" :show-file-list="false" v-if="cover == '' ">
                        <i class="el-icon-plus" style="width: 20px;height: 20px;font-size: 20px;color: #2970FF;margin-top:40px;text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);font-weight: 400;"></i>
                        <div style="font-size: 12px;color: #666666">点击上传封面</div>
                    </el-upload>
                </div>
                <div style="width: 170px;height: 106px;position: relative;margin-top: -30px;margin-left: 12px" v-if="cover != ''">
                    <img :src="cover" style="width: 156px;height: 106px;border-radius: 6px;object-fit: cover;">
                    <div style="background:#ff7575;color:#FFF;text-align:center;width:15px;line-height:20px;position: absolute;left: 140px;bottom: 87px; cursor:pointer;user-select: none;" @click="removeImg2()">
                        <i class="el-icon-delete"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Editor from "@/components/common/Editor.vue"
    export default {
        components: {
            Editor
        },
        computed: {
            //加载后马上执行
            user(){
                return this.$store.state.user;

            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data() {
            return {
                dialogShow :false,
                organ: 1,
                kwtype:'',
                channel_list:[],
                type_ids:"",
                type_list:[],
                thumb:"",
                title:"",
                content:"",
                id:"",
                spaceSize:0,
                cover_imgs:[],
                thumbs:[
                    "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail1.jpg",
                    "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail2.jpg",
                    "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail3.jpg",
                    "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail4.png"
                ],
                cover:"",
                coverIndex:0,

            }
        },
        watch: {
        },
        mounted: function () {
            this.id = this.$route.params.id;
            this.getHeadLineInfo();
        },
        methods:{
            getHeadLineInfo(){
                var that = this;
                that.newApi.getHeadLineInfo({id:that.id}).then((res => {
                    if(res.isSuccess == 1){
                        that.title = res.data.title;
                        that.content = res.data.content;
                        that.cover = res.data.cover;
                    }
                }));
            },

            add(){
                var that = this;
                var params = {};
                params.id = that.id;
                // 标题
                params.title = that.title;
                if(params.title == ""){
                    this.utils.err("标题不能为空");
                    return false;
                }
                // 内容
                let chil = that.$refs.ch;
                let content = chil.get_content();
                content = content.replace(/amp;/g,"");
                params.content = content;
                if(params.content == ""){
                    that.utils.err("请填写内容");
                    return false;
                }
                //  封面
                if(that.cover == ""){
                    that.utils.err("请上传封面");
                    return false;
                }
                params.cover = that.cover;
                console.log(params);
                that.newApi.editHeadLine(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.utils.a('/content/info/' + that.id + "/3");
                    }
                })
            },

            //监听选中封面样式
            change_border(index){
                this.coverIndex = index;
                this.cover = this.cover_imgs[index];
            },
            upload_img2:function(params){
                var file = params.file;
                var that = this;
                const isLt5M = file.size / 1024 / 1024 < 20
                if (!isLt5M) {
                    that.utils.err('上传文件大小不能超过 20MB!')
                    return false
                }
                that.utils.upload(file,function (url){
                    if (!url){
                        return false;
                    }
                    that.cover = url.url;
                })
            },
            //移除封面
            removeImg2:function(){
                var that = this;
                that.utils.confirm("移除这个封面？",function(){
                    that.cover= "";
                })
            },

            //生成文章封面
            create_thumb_pic(){
                var that = this;
                var imgReg = /<img.*?(?:>|\/>)/gi;
                var srcReg = /src=['"]?([^'"]*)['"]?/i;
                let chil = that.$refs.ch;
                var content = chil.get_content();
                content = content.replace(/amp;/g, "");
                var arr = content.match(imgReg);
                console.log(arr);
                if (arr == null) {
                    that.cover_imgs = that.thumbs;
                    that.dialogShow = true;
                } else {
                    var imgs = [];
                    for (var i = 0; i < arr.length && i < 10; i++) {
                        var src = arr[i].match(srcReg);
                        imgs.push(src[1]);
                    }
                    var contains = 0;
                    for (let i = 0; i < imgs.length; i++) {
                        if(imgs[i].includes("baigongbao")){
                            contains++;
                        }
                    }
                    if (contains != imgs.length)  {
                        const loading = this.$loading({
                            lock: true,
                            text: '网络图片转换中,请等待',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        //替换需要转换OSS的图片地址
                        that.newApi.uploadThumbImg({
                            content: chil.get_content()
                        }).then((res) => {
                            that.cover_imgs = res.data.oss_url;
                            chil.set_content(res.data.content);
                            that.dialogShow = true;
                            loading.close();
                        }, 1).catch((err) => {
                            console.log(err)
                        })
                    }else{
                        that.cover_imgs = imgs;
                        that.dialogShow = true;
                    }
                }
            },
            getCaption:function(obj){
                var index=obj.lastIndexOf("=");
                obj=obj.substring(index+1,obj.length);
                obj=obj.slice(0);
                obj=obj.replace(/'/g,'');
                obj=obj.replace(/>/g,'');
                return obj;
            },
        }
    }
</script>

<style scoped>

    .data{
        margin:5px 0 0 20px;

    }
    .determine {
        width: 112px;
        height: 40px;
        background: #031F88;
        border-radius: 5px 5px 5px 5px;
        margin:180px 0 0 300px;
        display:flex;
        justify-content: center;
    }
    .determine_th {
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        margin-top:10px;
        cursor:pointer;
        float:left
    }
    .avatar {
        width: 280px;
        height: 180px;
        display: block;
        float: left;
        margin-top: 10px;
        border-radius: 0px;
        margin-left: 105px;
        margin-top:-40px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        cursor: pointer;
        /*position: relative;*/
        overflow: hidden;
        float: left;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .el-dropdown {
        color: #000;
    }
    /deep/ .el-upload-dragger {
        width: 156px;
        height: 106px;
    }
    /deep/ .el-radio__label {
        font-size: 15px;
    }
    /deep/ .el-checkbox__label {
        font-size: 16px;
    }
    /deep/ .el-checkbox__inner {
        width: 16px;
        height: 15px;
    }
    /deep/ .upload_url .el-upload-dragger {
        width: 330px !important;
        height: 164px !important;
    }
    /deep/ .el-dialog__body {
        display: flex;
        flex-direction: column;
    }
    .active{
        box-shadow:0 0 5px 10px #409eff;
    }
    .editor-container {
        height: 300px; /* 设置固定高度 */
        resize: none; /* 禁用调节功能 */
    }

</style>
